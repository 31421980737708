import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { inviteFriendsLink } from '../../../../../../app/constants';
import type { IUser } from '../../../../../../app/auth/auth.interfaces';
import './InviteFriends.scss';
import { useAppSelector } from '../../../../../../app/store';
import mascotIcon from '../../../../../../assets/images/mascot/mascot_love.svg';
import AppButton from '../../../../app-button/AppButton';

interface IInviteFriendsProps {
  setShouldDisplayShareLink: Dispatch<SetStateAction<boolean>>;
}

export const InviteFriends: FunctionComponent<IInviteFriendsProps> = ({
  setShouldDisplayShareLink,
}) => {
  const { t } = useTranslation();
  const { user: userInfo } = useAppSelector((store) => store.userReducer);

  const inviteFriendsShareText = (): string => {
    const userName = userInfo && !!userInfo.name ? ' ' + userInfo.name : '';
    return t('settingsInviteFriendsShareText').replace('{user_name}', userName);
  };

  // if shared failed (catch) - it mean there is no support on share() -> open the copy link component
  const onInviteFriends = async () => {
    try {
      const shareData = {
        title: '',
        text: inviteFriendsShareText(),
        url: inviteFriendsLink((userInfo as IUser)?.id),
      };
      await navigator.share(shareData);
    } catch (err) {
      setShouldDisplayShareLink(true);
    }
  };

  return (
    <div className="invite-friends-flex">
      <div className="invite-friends-header-and-mascot-container">
        <h3 className="invite-friends-card-header">{t('settingsInviteFriendsTitle')}</h3>
        <img className="invite-friends-mascot-image" src={mascotIcon} alt="mascot" />
      </div>
      <p className="invite-friends-text">{t('settingsInviteFriendsParagraph')}</p>
      <AppButton
        id="invite-friends-action-button"
        data-testid="invite-friends-action-button"
        className="invite-friends-button"
        onClick={onInviteFriends}
      >
        {t('settingsInviteFriendsButtonText')}
      </AppButton>
    </div>
  );
};
